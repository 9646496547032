.footer-div {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 4;
  margin: 0 auto;
  bottom: 0;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: rgba(255, 255, 255, 0.4); /* Semi-transparent background */
  backdrop-filter: blur(10px);   /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border-radius: 4px 4px 0 0;
  padding: 16px;
  transition: transform 0.1s ease, box-shadow 0.1s ease; /* Smooth transitions */
  transform-style: preserve-3d; /* Enable 3D transformations */
}

.footer-text {
  text-align: center;
  color: red;
  font-family: "Google Sans Regular";
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}


.footer-text.sm {
  text-align: center;
  color: red;
  font-family: "Google Sans Regular";
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}